<template>
  <el-dialog
      title="复诊建议"
      :visible.sync="open"
      :close-on-click-modal="true"
      @close="closeFn"
      width="50%">
    <template #default>
      <div class="dialog-content">
        <div class="left-box">
          <el-scrollbar wrap-class="scrollbar-wrapper">
            <ul class="left">
              <template v-for="(item,index_) in submit_list" >
                <li @click="tab(index_)" :class="{ 'is_active' : index === index_ }" :key="index_" >{{ item.creatTime }}</li>
              </template>
              <el-button class="new_but" type="primary" size="small" @click="add" >新建复诊</el-button>
            </ul>
          </el-scrollbar>
        </div>
        <div class="right-box">
          <el-scrollbar wrap-class="scrollbar-wrapper">
            <div class="right right_">
              <el-form :model="jsonObject" label-width="105px" ref="jsonRef" hide-required-asterisk size="small" >
                <el-form-item
                      prop="visitTime"
                      :rules="[
                        { required: true, message: '请选择', trigger: 'blur' }
                      ]">
                  <template #label>
                    <span class="title title_" >复诊时间</span>
                  </template>
                  <template v-if="!submit_list[index].is_edit">
                    <el-select v-model="jsonObject.visitTime" clearable placeholder="请选择">
                      <el-option v-for="item in visitTime_list"
                                 :key="item.key"
                                 :label="item.value"
                                 :value="item.key"></el-option>
                    </el-select>
                  </template>
                  <template v-else >
                    <p>{{ visitTime_name }}</p>
                  </template>
                </el-form-item>
                <el-form-item>
                  <template #label>
                    <span class="title title_" >复诊建议</span>
                  </template>
                  <template v-if="!submit_list[index].is_edit" >
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="请输入"
                        clearable
                        v-model="jsonObject.visitCommend">
                    </el-input>
                  </template>
                  <template v-else>
                    <p>{{ jsonObject.visitCommend }}</p>
                  </template>
                </el-form-item>
              </el-form>
              <template v-if="submit_list[index].is_edit">
                <el-button size="small" :disabled="submit_list[index].timeVisit" @click="edit" >编辑</el-button>
              </template>
              <template v-else>
                <el-button size="small" @click="confirm" >确认</el-button>
              </template>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "proposal-dialog",
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    patientOpenId: {
      type: String,
      default: () => {
        return "";
      }
    },
    patientId: {
      type: String,
      default: () => {
        return "";
      }
    }
  },
  data() {
    return {
      index: 0,
      is_show_image: false,
      imgList: [],
      submit_list: [],
      visitTime_list: [
        {
          key: '1',
          value: "一个月",
        },
        {
          key: '3',
          value: "三个月",
        },
        {
          key: '6',
          value: "六个月",
        },
        {
          key: '12',
          value: "一年",
        },
      ]
    }
  },
  watch: {
    list: {
      handler(newVal) {
        this.index = 0;
        this.submit_list = JSON.parse(JSON.stringify(newVal));
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    visitTime_name() {
      let str = "",
          arr = (this.visitTime_list || []).filter(item => item.key == this.jsonObject.visitTime);

      if(arr.length > 0) {
        str = arr.map(item => item.value)[0];
      }

      return str;
    },
    open: {
      get() {
        return this.value
      },
      set(v) {
        console.log(v);
        this.$emit("input",v)
      },
    },
    jsonObject: {
      get() {
        let obj = {};
        if(this.submit_list.length > 0) {
          obj = this.submit_list[this.index].jsonObject;
        }
        return obj;
      },
      set(value) {
        this.submit_list[this.index].jsonObject = value;
      },
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    closeFn() {
      this.$refs["jsonRef"].clearValidate();
    },
    edit() {
      this.submit_list[this.index].is_edit = false;
    },
    confirm() {
      this.$refs["jsonRef"].validate((valid) => {
        if(valid){
          console.log(this.jsonObject)
          let obj = {
            ...this.jsonObject,
            visitId: this.submit_list[this.index].visitId,
            id: this.patientId
          }
          this.$post("colondoc/confirm",obj)
            .then(res => {
              if(res) {
                let data_ = res.data;
                this.submit_list[this.index].is_edit = true;
                this.submit_list[this.index].creatTime = data_.creatTime;
                this.submit_list[this.index].visitId = data_.visitId;
              }
            })
        }
      })
    },
    add() {
      let arr = (this.submit_list || []).filter(item => !item.visitId);

      if(arr.length > 0) {
        this.$message.error("复诊任务已创建，请点击编辑功能添加复诊信息");
        return false;
      }

      this.$get("colondoc/check",{
        patientOpenId: this.patientOpenId
      })
        .then(res => {
          if(res) {
            let data_ = {
              ...res.data,
              is_edit: true,
            };
            this.submit_list.push(data_);
          }
        })
    },
    tab(index) {
      this.$refs["jsonRef"].clearValidate();
      setTimeout(() => {
        this.index = index;
      },100)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/TestKit/TestKitDialog.scss";
.right{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.title_{
  &::after{
    top: -2px;
  }
}
</style>
