<template>
  <div>
    <el-dialog
        title="报告"
        :visible.sync="open"
        @close="closeFn"
        :close-on-click-modal="true"
        width="50%">
      <template #default>
        <div class="dialog-content">
          <div class="left-box">
            <el-scrollbar wrap-class="scrollbar-wrapper">
              <ul class="left">
                <template v-for="(item,index_) in submit_list" >
                  <li @click="tab(index_)" :class="{ 'is_active' : index_ === index }" :key="index_" >{{ item.reportGroup }}</li>
                </template>
                <el-button class="new_but" type="primary" size="small" @click="add" >新建分组</el-button>
              </ul>
            </el-scrollbar>
          </div>
          <div class="right-box">
            <el-scrollbar wrap-class="scrollbar-wrapper">
              <div class="right">
<!--                <template v-if="endoscope.length > 0" >-->
                <template v-if="true" >
                  <div class="report-image">
                    <div class="title">内镜报告</div>
                    <ul class="image-ul">
                      <template v-for="(item,index) in endoscope" >
                        <li :key="index">
                          <el-image :src="item.endoscopePath" :fit="fit_" ></el-image>
                          <div class="image_hover">
                            <i class="el-icon-zoom-in" @click="see_image(endoscope,index,'endoscopePath')" ></i>
                            <template v-if="upload_in" >
                              <i class="el-icon-delete" @click="del_image('endoscope',index)" ></i>
                            </template>
                          </div>
                        </li>
                      </template>
                      <template v-if="upload_in" >
                        <uploadView key_name="endoscope" @confirm="upload_confirm" />
                      </template>
                    </ul>
                  </div>
                </template>
<!--                <template v-if="pathology.length > 0" >-->
                <template v-if="true" >
                  <div class="report-image">
                    <div class="title">病理报告</div>
                    <ul class="image-ul">
                      <template v-for="(item,index) in pathology" >
                        <li :key="index">
                          <el-image :src="item.pathologyPath" :fit="fit_" ></el-image>
                          <div class="image_hover">
                            <i class="el-icon-zoom-in" @click="see_image(pathology,index,'pathologyPath')" ></i>
                            <template v-if="upload_in" >
                              <i class="el-icon-delete" @click="del_image('pathology',index)" ></i>
                            </template>
                          </div>
                        </li>
                      </template>
                      <template v-if="upload_in" >
                        <uploadView key_name="pathology" @confirm="upload_confirm" />
                      </template>
                    </ul>
                  </div>
                </template>
<!--                <template v-if="other.length > 0">-->
                <template v-if="true">
                  <div class="report-image">
                    <div class="title">其他报告</div>
                    <ul class="image-ul">
                      <template v-for="(item,index) in other" >
                        <li :key="index">
                          <el-image :src="item.otherPath" :fit="fit_" ></el-image>
                          <div class="image_hover">
                            <i class="el-icon-zoom-in"  @click="see_image(other,index,'otherPath')" ></i>
                            <template v-if="upload_in" >
                              <i class="el-icon-delete" @click="del_image('other',index)" ></i>
                            </template>
                          </div>
                        </li>
                      </template>
                      <template v-if="upload_in" >
                        <uploadView key_name="other" @confirm="upload_confirm" />
                      </template>
                    </ul>
                  </div>
                </template>
              </div>
            </el-scrollbar>
            <template v-if="submit_list.length > 0" >
              <div class="but_box_">
                <template v-if="!upload_in" >
                  <el-button type="primary" size="small" @click="upload_click" >上传</el-button>
                </template>
                <template v-else>
                  <el-button type="primary" size="small" @click="upload_submit" >保存</el-button>
                  <el-button size="small" @click="upload_cancel" >取消</el-button>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
    </el-dialog>

    <el-image-viewer
        v-if="is_show_image"
        :on-close="closeImgViewer"
        :z-index="9999"
        :url-list="imgList" />
  </div>
</template>

<script>
import uploadView from "@/components/TestKit/components/upload-view";
export default {
  name: "report-dialog",
  components: {
    uploadView
  },
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    patientOpenId: {
      type: String,
      default: () => {
        return "";
      }
    },
    patientId: {
      type: String,
      default: () => {
        return "";
      }
    },
  },
  data() {
    return {
      index: 0,
      is_show_image: false,
      imgList: [],
      fit_: "cover",
      submit_list: [],
      upload_in: false,
    }
  },
  watch: {
    list: {
      handler(newVal) {
        this.index = 0;
        this.submit_list = JSON.parse(JSON.stringify(newVal));
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    open: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input",v)
      },
    },
    endoscope: function() {
      let arr = [];
      if(this.submit_list.length > 0 && this.submit_list[this.index].jsonObject !== null ) {
        arr = this.submit_list[this.index].jsonObject.endoscope;
      }

      return arr;
    },
    other: function() {
      let arr = [];
      if(this.submit_list.length > 0 && this.submit_list[this.index].jsonObject !== null ) {
        arr = this.submit_list[this.index].jsonObject.other;
      }

      return arr;
    },
    pathology: function() {
      let arr = [];
      if(this.submit_list.length > 0 && this.submit_list[this.index].jsonObject !== null ) {
        arr = this.submit_list[this.index].jsonObject.pathology;
      }

      return arr;
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    del_image(str,index) {
      console.log(str)
      console.log(index)
      this.submit_list[this.index].jsonObject[str] = (this.submit_list[this.index].jsonObject[str] || []).filter((item,index_) => index !== index_);
      console.log(this.submit_list[this.index].jsonObject[str][index])
    },
    upload_submit() {
      console.log(this.submit_list)
      let obj = {
        checkReportId: this.submit_list[this.index].checkReportId,
        id: this.submit_list[this.index].id,
        jsonObject: this.submit_list[this.index].jsonObject,
      }
      this.$post("colondoc/submitreport",obj)
        .then(res => {
          if(res) {
            console.log(res);
            this.upload_in = false;
            this.$message.success("保存成功");
          }
        })
    },
    upload_cancel() {
      this.upload_in = false;
    },
    upload_click() {
      this.upload_in = true;
    },
    upload_confirm(e) {
      console.log(e);
      this.submit_list[this.index].jsonObject[e.key_name] = [
          ...this.submit_list[this.index].jsonObject[e.key_name],
          ...e.arr
      ]
    },
    add() {
      this.$post("colondoc/group",{
        patientId: this.patientId
      })
        .then(res => {
          if(res) {
            console.log(res)
            let data_ = res.data;
            this.submit_list.push(data_);
          }
        })
    },
    see_image(arr_,index,key) {
      let arr = arr_.map(item => item[key])
      let before = arr.slice(0,index);
      let after = arr.slice(index,arr.length);
      this.imgList = after.concat(before);
      this.is_show_image = true;
    },
    closeImgViewer() {
      this.is_show_image = false;
    },
    tab(index) {
      this.upload_in = false;
      this.index = index;
    },
    closeFn() {
      this.upload_in = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/TestKit/TestKitDialog.scss";
</style>
