<template>
  <div>
    <el-dialog title="肠道准备详情" :visible.sync="open" :close-on-click-modal="true" width="80%">
      <template #default>
        <div class="dialog-content">
          <div class="left-box">
            <el-scrollbar wrap-class="scrollbar-wrapper">
              <ul class="left">
                <template v-for="(item, index_) in list">
                  <li @click="tab(index_)" :class="{ 'is_active': index_ === index }" :key="index_">{{ item.time }}
                  </li>
                </template>
              </ul>
            </el-scrollbar>
          </div>
          <div class="right-box">
            <el-scrollbar wrap-class="scrollbar-wrapper">
              <div class="right">
                <div class="time">
                  <div class="time-left">
                    <div class="title">末次进食时间：{{ jsonObject.lastEatTime }}</div>
                    <div class="title">首次喝药时间：{{ jsonObject.laxativesTimes }}</div>
                    <div class="title">末次喝药时间：{{ jsonObject.laxativesTimee }}</div>
                  </div>
                  <div class="time-right">
                    <div class="title">预约单：</div>
                    <el-image @click="see_image([jsonObject.inspectPath], 0)" :src="jsonObject.inspectPath" :fit="fit_">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </div>
                <ul class="content">
                  <template v-for="(item, index) in jsonObject.picList">
                    <li :key="index">
                      <div class="content-left">
                        <div class="content-left-title">
                          <p>{{ item.createTime }}</p>
                          <template v-if="item.result === '1'">
                            <p class="success">肠道准备评估：很好</p>
                          </template>
                          <template v-if="item.result === '2'">
                            <p class="success">肠道准备评估：较好</p>
                          </template>
                          <template v-if="item.result === '3'">
                            <p class="fail">肠道准备评估：很差</p>
                          </template>
                          <template v-if="item.result === '4'">
                            <p class="fail">肠道准备评估：较差</p>
                          </template>
                        </div>
                        <div class="content-left-image">
                          <el-image @click="see_image([item.toiletPicPath, item.cleanPicPath], 0)"
                            :src="item.toiletPicPath" :fit="fit_"></el-image>
                          <el-image @click="see_image([item.toiletPicPath, item.cleanPicPath], 1)"
                            :src="item.cleanPicPath" :fit="fit_"></el-image>
                        </div>
                      </div>
                      <template v-if="!item.result">
                        <div class="reply-box">
                          <el-form :ref="`form${index}`" hide-required-asterisk :model="jsonObject.picList[index]"
                            v-if="jsonObject.picList[index]" size="small" label-width="80px">
                            <el-form-item label="评估" prop="result_" :rules="[
                              { required: true, message: '请选择', trigger: 'blur' }
                            ]">
                              <template v-if="item.is_reply">
                                <span></span>
                              </template>
                              <template v-else>
                                <el-select v-model="jsonObject.picList[index].result_" clearable placeholder="请选择">
                                  <el-option v-for="item_ in result_box" :key="item_.key" :label="item_.value"
                                    :value="item_.key"></el-option>
                                </el-select>
                              </template>
                            </el-form-item>
                            <el-form-item label="备注">
                              <template v-if="item.is_reply">
                                <span></span>
                              </template>
                              <template v-else>
                                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入"
                                  maxlength="500" show-word-limit clearable v-model="jsonObject.picList[index].remark_">
                                </el-input>
                              </template>
                            </el-form-item>
                          </el-form>
                          <div class="but_box">
                            <template v-if="item.is_reply">
                              <el-button size="small" @click="replyClick(item, index)">回复</el-button>
                            </template>
                            <template v-else>
                              <el-button size="small" @click="replyConfirm(item, index)">确认</el-button>
                            </template>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="content-right">
                          <el-tooltip class="item" popper-class="tooltip-class" effect="dark" :disabled="!item.remark"
                            :content="item.remark" placement="top-start">
                            <p>备注：{{ item.remark }}</p>
                          </el-tooltip>
                        </div>
                      </template>
                    </li>
                  </template>
                </ul>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-image-viewer v-if="is_show_image" :on-close="closeImgViewer" :z-index="9999" :url-list="imgList" />
  </div>
</template>

<script>
export default {
  name: "details-dialog",
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      index: 0,
      is_show_image: false,
      imgList: [],
      fit_: "cover",
      result_box: [
        {
          key: "0",
          value: "未识别",
        },
        {
          key: "1",
          value: "很好",
        },
        {
          key: "2",
          value: "较好",
        },
        {
          key: "3",
          value: "很差",
        },
        {
          key: "4",
          value: "较差",
        },
      ],
      submit_list: []
    }
  },
  watch: {
    list: {
      handler(newVal) {
        this.index = 0;
        this.submit_list = JSON.parse(JSON.stringify(newVal));
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    open: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      },
    },
    jsonObject: {
      get() {
        let obj = {};
        if (this.submit_list.length > 0) {
          obj = this.submit_list[this.index].jsonObject;
        }
        console.log(obj)
        return obj;
      },
      set(value) {
        this.submit_list[this.index].jsonObject = value;
      },
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    replyClick(item, index) {
      this.jsonObject.picList[index].is_reply = false;
    },
    replyConfirm(item, index) {
      this.$refs[`form${index}`][0].validate((valid) => {
        if (valid) {
          let obj = {
            bowlPrepareId: this.jsonObject.bowlPrepareId,
            defecatPicInfoId: item.defecatPicInfoId,
            docOpenId: this.jsonObject.docOpenId,
            patientOpenId: this.jsonObject.patientOpenId,
            remark: item.remark_,
            result: item.result_,
          }

          this.$post("colondoc/submitBowl", obj)
            .then(res => {
              console.log(res);
              if (res) {
                // let data_ = res.data;
                item["remark"] = item.remark_;
                item["result"] = item.result_;
              }
            })

        }
      })
    },
    tab(index) {
      this.index = index;
    },
    see_image(arr_, index) {
      let arr = [...arr_];
      let before = arr.slice(0, index);
      let after = arr.slice(index, arr.length);
      this.imgList = after.concat(before);
      this.is_show_image = true;
    },
    closeImgViewer() {
      this.is_show_image = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/TestKit/TestKitDialog.scss";
</style>
