<template>
  <div class="upload-box">
    <div class="upload" @click="but" >
      <i class="el-icon-plus" ></i>
    </div>
    <input v-show="file_but_show" ref="file" type="file" id="videoInput" @change="onchange_" :accept="accept" multiple="multiple"/>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    accept: {
      type: String,
      default: () => {
        return ".jpg,.jpeg,.bmp,.png,.tif,.gif";
      }
    },
    action: {
      type: String,
      default: () => {
        return `colondoc/upload`
      }
    },
    key_name: {
      type: String,
      default: () => {
        return ""
      }
    }
  },
  data() {
    return {
      file_but_show: false,
      disabled: false,
      but_loading: false,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    upload_api(e) {
      let url = `${this.action}?time=${new Date()}`
      this.$post(url,e)
      .then(res => {
        if(res) {
          console.log(res)
          let data_ = res.data;
          let arr = data_.map(item => {
            let obj = {};
             obj[`${this.key_name}Name`] =  item.fileName;
             obj[`${this.key_name}Path`] =  item.fileUrl;
            return obj
          })
          let obj = {
            arr,
            key_name: this.key_name
          }
          this.$emit("confirm",obj)
        }
      })
    },
    onchange_(e) {
      let formData = new FormData(),
          files_list = ([...e.target.files] || []).filter(item => item.type.indexOf("image") !== -1);

      if(files_list.length > 10) {
        this.$message.error("单次上传不能超过10涨");
        return false;
      }

      // console.log(files_list);
      for (const file of files_list) {
        formData.append("files",file);
      }
      // console.log(formData.get("files"));
      this.upload_api(formData);
    },
    but() {
      this.$refs["file"].click();
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-box{
  .upload{
    width: 186px;
    height: 103px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      border-color: #409eff;
      .el-icon-plus{
        //color: #409eff;
      }
    }
    .el-icon-plus{
      font-size: 24px;
      color: #8c939d;
    }
  }
}
</style>
